<template>
  <el-dialog
    class="address-form"
    title="创建地址"
    :center="true"
    :visible.sync="showAddressModal"
    width="650px"
    appendToBody
  >
    <el-form :model="addressForm" :rules="addressRules" ref="addressForm" label-width="130px">
      <el-form-item prop="district_id">
        <template v-slot:label>
          <span class="require"></span>
          <span>地址信息：</span>
        </template>
        <AreaSelect
          :provinceId="addressForm.province_id"
          :cityId="addressForm.city_id"
          :districtId="addressForm.district_id"
          @change="areaChange"
        ></AreaSelect>
      </el-form-item>
      <el-form-item prop="address">
        <template v-slot:label>
          <span class="require"></span>
          <span>详细地址：</span>
        </template>
        <el-input
          v-model="addressForm.address"
          placeholder="请输入详细地址，如道路，门牌号，校区，楼栋数，单元等"
          type="textarea"
          :rows="3"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮政编码：" prop="zip_code">
        <el-input v-model="addressForm.zip_code" placeholder="请输入邮编" type="text"></el-input>
      </el-form-item>
      <el-form-item prop="user_name">
        <template v-slot:label>
          <span class="require"></span>
          <span>收货人姓名：</span>
        </template>
        <el-input
          v-model="addressForm.user_name"
          placeholder="长度不超过25个字符"
          type="text"
          :maxlength="25"
        ></el-input>
      </el-form-item>
      <el-form-item prop="mobile">
        <template v-slot:label>
          <span class="require"></span>
          <span>手机号码：</span>
        </template>
        <el-input v-model="addressForm.mobile" placeholder="请输入手机号" type="text" :maxlength="11"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer clear">
      <el-checkbox class="f-left" v-model="addressForm.is_default">设为默认地址</el-checkbox>
      <el-button class="f-right" type="primary" size="small" @click="confirmAddress">确认修改</el-button>
    </div>
  </el-dialog>
</template>
<script>
import AreaSelect from "@/components/AreaSelect.vue";
export default {
  name: "AddressForm",
  components: { AreaSelect },
  data() {
    return {
      showAddressModal: false,
      addressForm: {
        province_id: "",
        city_id: "",
        district_id: "",
        address: "",
        zip_code: "",
        user_name: "",
        mobile: "",
        is_default: false
      },
      addressRules: {
        district_id: [
          { required: true, message: "地址信息不能为空", trigger: "chnage" }
        ],
        address: [
          { required: true, message: "详细地址不能为空", trigger: "blur" }
        ],
        user_name: [
          { required: true, message: "收货人姓名不能为空", trigger: "blur" }
        ],
        mobile: [
          { required: true, message: "手机号码不能为空", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    init() {
      this.addressForm.province_id = "";
      this.addressForm.city_id = "";
      this.addressForm.district_id = "";
      this.addressForm.address = "";
      this.addressForm.zip_code = "";
      this.addressForm.user_name = "";
      this.addressForm.mobile = "";
    },
    showForm() {
      this.showAddressModal = true;
      this.init();
    },
    areaChange(area) {
      this.addressForm.province_id = area.provinceId;
      this.addressForm.city_id = area.cityId;
      this.addressForm.district_id = area.districtId;
    },
    confirmAddress() {
      this.$refs["addressForm"].validate(async valid => {
        if (!valid) {
          return;
        }
        await this.$http.post("/api/app/address", {
          province_id: this.addressForm.province_id,
          city_id: this.addressForm.city_id,
          district_id: this.addressForm.district_id,
          address: this.addressForm.address,
          zip_code: this.addressForm.zip_code,
          user_name: this.addressForm.user_name,
          mobile: this.addressForm.mobile,
          is_default: this.addressForm.is_default ? 1 : 2
        });
        this.showAddressModal = false;
        this.$emit("success", this.addressForm);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.address-form {
  /deep/ {
    .el-dialog__header {
      padding: 32px;
      padding-bottom: 32px;
    }

    .el-dialog__title {
      height: 24px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(89, 89, 89, 1);
      line-height: 24px;
    }

    .el-dialog__headerbtn {
      top: 37px;
      right: 50px;
    }

    .el-dialog__body {
      padding: 0 50px;
    }

    .el-dialog__footer {
      padding: 18px 50px 32px 50px;
    }

    .el-form-item.is-required:not(.is-no-asterisk)
      > .el-form-item__label:before {
      content: "";
    }

    .require {
      width: 12px;
      height: 13px;
      display: inline-block;
      margin-right: 6px;
      background-size: 100% 100%;
      background-image: url("~@/assets/images/common/require@2x.png");
    }

    .el-form-item__label {
      height: 21px;
      font-size: 15px;
      color: rgba(89, 89, 89, 1);
      line-height: 34px;
      padding-right: 16px;
      position: relative;
    }

    .el-form-item__content {
      line-height: 34px;
    }

    .el-select {
      width: 120px !important;

      .el-input {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
      }

      .el-input__inner {
        height: 34px;
        line-height: 34px;
      }
    }

    .el-checkbox {
      display: flex;
      align-items: center;
    }

    .el-checkbox__label {
      height: 21px;
      font-size: 15px;
      color: rgba(89, 89, 89, 1);
      line-height: 21px;
      padding-left: 4px;
    }
  }
}
</style>