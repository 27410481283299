<template>
  <div class="main-container">
    <div class="bread">
      <router-link
        :to="{name:'MemberIntegral',query:{tab:'store'}}"
      >{{$store.state.commonSetting.point_info.point_name}}商城</router-link>
      <span class="slash">&gt;</span>
      <span class="current">{{detail.name}}</span>
    </div>
    <div class="info">
      <img class="cover" v-if="detail.goods_type == 2" :src="detail.cover_img" />
      <div class="right">
        <div class="name">{{detail.name}}</div>
        <div class="integral-wrap">
          <span class="integral">{{detail.exchange_price}}</span>
          <span class="integral-label">{{$store.state.commonSetting.point_info.point_name}}</span>
          <span class="stock-wrap">
            <span class="sale-num">{{detail.sales_num}}</span>
            <span>人已报名</span>
            <span class="line">|</span>
            <span>剩余：</span>
            <span class="surplus">{{detail.surplus}}</span>
            <span>名额</span>
          </span>
        </div>
        <div class="btn-wrap">
          <a
            v-if="detail.is_check!=2||detail.goods_type==2"
            :class="{'btn-disabled':detail.surplus==0}"
            class="btn btn-primary"
            @click="beginExchange"
          >立即兑换</a>
          <router-link
            v-if="detail.is_check!=2||detail.goods_type==2"
            class="btn btn-blank"
            :to="{name:detail.goods_type==1?'CourseShow':'BookDetail',params:{id:detail.goods_id}}"
          >查看详情</router-link>
          <a
            v-if="detail.is_check==2&&detail.goods_type==1"
            class="btn btn-primary"
            @click="joinStudy"
          >立即学习</a>
        </div>
      </div>
    </div>
    <div class="detail-wrap">
      <div v-if="detail.goods_type" class="title">{{detail.goods_type==1?'课程介绍':'图书介绍'}}</div>
      <div class="detail" v-html="detail.intro"></div>
    </div>
    <el-dialog
      class="dialog-address"
      title="配送至"
      :visible.sync="showAddressModal"
      width="681px"
      appendToBody
      center
    >
      <Address v-model="addressId"></Address>
      <div slot="footer" class="dialog-footer clear">
        <el-button type="default" size="small" @click="showAddressModal=false">取消</el-button>
        <el-button type="primary" size="small" @click="confirmExchange">立即兑换</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Address from "../../components/Address";
export default {
  name: "IntegralGoodsDetail",
  components: {
    Address
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      showAddressModal: false,
      addressId: ""
    };
  },
  async created() {
    let data = await this.$http.get("/api/integral/shop/detail/" + this.id);
    data.surplus = data.stock - data.sales_num;
    this.detail = data;
  },
  methods: {
    async exchange() {
      let data = {
        shop_id: this.detail.goods_id,
        product_number: 1,
        integral_id: this.id
      };
      if (this.detail.goods_type == 1) {
        data.type = this.detail.course_type;
      } else {
        data.type = 12;
        data.address_id = this.addressId;
      }
      await this.$http.post("/api/app/order/downOrder", data);
      this.$success("兑换成功");
      this.$router.push({ name: "MemberIntegral", query: { tab: "record" } });
    },
    async beginExchange() {
      if (this.detail.surplus == 0) {
        return;
      }
      if (this.detail.goods_type == 1) {
        await this.exchange();
      } else {
        this.showAddressModal = true;
      }
    },
    async confirmExchange() {
      if (this.addressId == "") {
        this.$warning("请选择收货地址");
        return;
      }
      await this.exchange();
    },
    async joinStudy() {
      await this.$http.post("/api/app/joinStudy", {
        course_basis_id: this.detail.goods_id,
        join_from: 3,
        course_type: this.detail.course_type
      });
      this.$router.push({
        name: "MemberStudy",
        query: { type: this.detail.course_type }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.bread {
  border-radius: 10px;
}
.dialog-address {
  /deep/ {
    .el-dialog__title {
      height: 24px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(89, 89, 89, 1);
      line-height: 24px;
    }
    .el-dialog__header {
      padding: 32px 0 50px 0;
    }
    .el-dialog__headerbtn {
      top: 37px;
      right: 49px;
    }
    .el-dialog__close {
      font-weight: bold;
    }
    .el-dialog {
      margin-top: 0 !important;
      top: 50%;
      transform: translateY(-50%);
    }
    .el-dialog__body {
      padding: 0 0 50px 50px;
    }
    .el-dialog__footer {
      padding-top: 0;
      padding-bottom: 32px;
    }
    .el-dialog__header {
      padding-bottom: 50px;
    }
    .el-button--default {
      color: #8c8c8c;
    }
    .address-wrap {
      width: 579px;
    }
    .list {
      .address {
        margin-right: 37px;
        margin-top: 30px;
        &:nth-child(1),
        &:nth-child(2) {
          margin-top: 0;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
    .dialog-footer {
      text-align: center;
      .el-button {
        width: 150px;
        height: 32px;
        border-radius: 16px;
      }
      .el-button + .el-button {
        margin-left: 30px;
      }
    }
  }
}
.info {
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  margin-top: 30px;
  padding: 30px 50px;
  margin-bottom: 30px;
  display: flex;
  .cover {
    width: 158px;
    height: 196px;
    margin-right: 25px;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
  }
  .name {
    height: 38px;
    font-size: 34px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(54, 56, 55, 1);
    line-height: 38px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 25px;
  }
  .integral-wrap {
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(238, 31, 31, 1);
    display: flex;
    height: 32px;
    margin-bottom: 50px;
  }
  .integral {
    font-size: 34px;
    line-height: 32px;
  }
  .integral-label {
    font-size: 22px;
    line-height: 34px;
  }
  .stock-wrap {
    margin-left: 30px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(89, 89, 89, 1);
    line-height: 38px;
    .sale-num {
      margin-right: 5px;
    }
    .line {
      margin: 0 20px;
      font-size: 14px;
    }
    .surplus {
      margin-right: 5px;
    }
  }
  .btn-wrap {
    .btn {
      width: 166px;
      height: 51px;
      display: inline-block;
      box-sizing: border-box;
      border-radius: 26px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 51px;
      text-align: center;
      cursor: pointer;
      margin-right: 25px;
    }
    .btn-primary {
      background: $primary-color;
      color: rgba(255, 255, 255, 1);
    }
    .btn-blank {
      background: rgba(255, 255, 255, 1);
      border: 1px solid $primary-color;
      color: $primary-color;
    }
    .btn-disabled {
      background: rgba(245, 245, 245, 1);
      color: #8c8c8c;
      cursor: not-allowed;
    }
  }
}
.detail-wrap {
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  margin-bottom: 70px;
  padding: 25px;
  .title {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(38, 38, 38, 1);
    line-height: 22px;
    position: relative;
    padding-left: 10px;
    margin-bottom: 15px;
    &::before {
      content: "";
      position: absolute;
      width: 3px;
      height: 14px;
      background: $primary-color;
      border-radius: 2px;
      top: 4px;
      left: 0;
    }
  }
  .detail {
    font-size: 15px;
    /deep/{
      img{
        max-width: 100%;
      }
    }
  }
}
</style>