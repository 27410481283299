<template>
  <div class="address-wrap">
    <div class="clearfix" v-if="addressList.length>0">
      <el-card
        class="address"
        :class="{'current':address.id==address_id}"
        shadow="never"
        v-for="address in addressList"
        :key="address.id"
        @click.native="selectAddress(address.id)"
      >
        <div
          class="title"
          :title="address.province_name+address.city_name+'（'+address.user_name+'收）'"
        >{{address.province_name+address.city_name+'（'+address.user_name+'收）'}}</div>
        <div
          class="detail"
        >{{address.province_name+' '+address.city_name+' '+address.district_name+' '+address.address}}</div>
        <img
          class="default"
          src="../images/book/address-default@2x.png"
          v-if="address.is_default==1"
        />
        <img class="ok" src="../images/book/address-ok@2x.png" v-if="address.id==address_id" />
      </el-card>
    </div>
    <div v-else class="empty">
      <div>
        <img class="img-loc" src="@/assets/images/integral/loc@2x.png" />
      </div>
      <div>
        <img class="img-add" src="@/assets/images/integral/address-add@2x.png" @click="addAddress" />
      </div>
    </div>
    <div class="clear">
      <span class="f-right add" @click="addAddress" v-if="addressList.length>0">新增收货地址</span>
    </div>
    <AddressForm ref="form" @success="getAddress"></AddressForm>
  </div>
</template>
<script>
import AddressForm from "./AddressForm.vue";
export default {
  name: "Address",
  components: { AddressForm },
  data() {
    return {
      addressList: [],
      address_id: ""
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: ["value"],
  async created() {
    await this.getAddress();
  },
  methods: {
    async getAddress() {
      this.addressList = await this.$http.get("/api/app/address");
      let address = this.addressList.filter(a => a.is_default == 1)[0];
      if (address) {
        this.address_id = address.id;
        this.$emit("change", address.id);
      }
    },
    addAddress() {
      this.$refs.form.showForm();
    },
    selectAddress(id) {
      this.address_id = id;
      this.$emit("change", id);
    }
  }
};
</script>
<style lang="scss" scoped>
.address-wrap {
  background-color: white;
  .expand,
  .add {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(231, 77, 61, 1);
    padding: 6px 12px;
    background: rgba(231, 77, 61, 0.06);
    border-radius: 14px;
    cursor: pointer;
    margin-top: 24px;
  }
  .collapse {
    width: 80px;
    height: 28px;
    background: rgba(231, 77, 61, 1);
    border-radius: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 28px;
    text-align: center;
    margin-top: 24px;
    cursor: pointer;
  }
}
.address {
  margin-top: 24px;
  float: left;
  width: 271px;
  height: 131px;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  border: 1px solid rgba(217, 217, 217, 1);
  margin-right: 16px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  &:nth-child(4n) {
    margin-right: 0;
  }
  .title {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(91, 96, 102, 1);
    line-height: 20px;
    margin-bottom: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .detail {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(140, 140, 140, 1);
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .default {
    position: absolute;
    width: 67px;
    height: 28px;
    right: 0;
    top: 0;
  }
  .ok {
    position: absolute;
    width: 42px;
    height: 34px;
    right: 0;
    bottom: 0;
  }
  &.current,
  &:hover {
    border: none;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  }
}
.empty {
  text-align: center;
  .img-loc {
    width: 35px;
    height: 34px;
    margin-bottom: 15px;
    margin-left: -46px;
  }
  .img-add {
    width: 129px;
    height: 25px;
    margin-left: -40px;
    cursor: pointer;
  }
}
</style>